const faq = () => {
    const questions = document.querySelectorAll('.showAnswer');

    questions.forEach(function(question) {
        question.addEventListener('click', function (event) {
            event.preventDefault(); // Prevent default anchor behavior

            // Toggle the '--shown' class on the parent .questions__item div
            const parentItem = this.closest('.questions__item');
            if (parentItem) {
                parentItem.classList.toggle('--shown');
            }
        });
    });
}
