$(function () {
    setHeaderScroll();
    hamburger();
    calendarInit();
    onlineCheckInForm();
    slickSliderIni();
    fancyBoxInit();
    checkbox();
    checkout();
    faq();
    // scrollTevealInit();
});

// function scrolling() {
// }

// function resizing() {
// }

// $(document).on('scroll', scrolling);
// $(window).on('resize', resizing);
