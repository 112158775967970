const checkbox = () => {
    const els = document.querySelectorAll('.form__label.--checkbox input')

    els.forEach(el => {
        el.addEventListener('change', (e) => {
            const checkbox = e.target
            const label = e.target.closest('.form__label')

            if(checkbox.checked) {
                label.classList.add('--selected')
            } else {
                label.classList.remove('--selected')
            }
        });
    });
}
