const onlineCheckInForm = () => {
    const checkInForm = document.getElementById('onOnlineCheckIn')

    if (checkInForm) {
        const fillAddressesButton = checkInForm.querySelector('[data-fill-addresses]')

        fillAddressesButton.addEventListener('click', function() {
            const firstAddressInput = checkInForm.querySelector('input[name="address[]"]');
            const firstAddressValue = firstAddressInput.value;

            if (firstAddressValue.trim() !== '') {
                const otherAddressInputs = checkInForm.querySelectorAll('input[name="address[]"]');
                otherAddressInputs.forEach(function(addressInput) {
                    addressInput.value = firstAddressValue;
                });

                oc.flashMsg({
                    message: 'Adresy byly vyplněny.',
                    type: 'success',
                    interval: 3
                });
                firstAddressInput.closest('.form-group').classList.remove('has-error')
            } else {
                oc.flashMsg({
                    message: 'Nejdříve doplňte adresu.',
                    type: 'error',
                    interval: 3
                });
                firstAddressInput.closest('.form-group').classList.add('has-error')
            }
        });
    }
}
