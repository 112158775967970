// https://fancyapps.com/fancybox/getting-started/

const fancyBoxInit = () => {

    const item = document.querySelector('[data-fancybox]')

    if(!item) return

    Fancybox.bind("[data-fancybox]", {
        // Your custom options
    });
};
